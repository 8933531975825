import React from 'react';
import { Link } from 'gatsby'
import logo from '../img/logo-word-mark-white.svg';
import fb_icon from '../img/facebook-icon-square.svg'
import li_icon from '../img/linkedin-icon-square.svg'
import twitter_icon from '../img/twitter-icon-square.svg'
import phone_icon from '../img/phone-green.svg'
import clock_icon from '../img/clock-green.svg'

export default function Footer(props) {
    return (
        <div id="footer">
            <div id="footer_top">
                <div id="footer_logo" className="flex-item">
                <Link to="/"><img src={logo} alt="insurance321 logo" width="40px"></img></Link>
                </div>
                <div className="flex-item item-coverage">
                    <h5>Coverages</h5>
                    <ul className="footer_list">
                        <li><Link to="/business-owners-policy">Business Owner's Policy</Link></li>
                        <li><Link to="/commercial-general-liability-insurance">General Liability Insurance</Link></li>
                        <li><Link to="/workers-compensation-insurance">Worker's Compensation Insurance</Link></li>                        
                        <li><Link to="/professional-liability-insurance">Professional Liability Insurance</Link></li>
                        <li><Link to="/commercial-auto-insurance">Commercial Auto Insurance</Link></li>
                        <li><Link to="/commercial-property-insurance">Commercial Property Insurance</Link></li>
                    </ul>
                </div>
                <div className="flex-item item-industries">
                    <h5>Industries</h5>
                    <ul className="footer_list">
                        <li><Link to="/building-contractor-insurance">Building Contractor Insurance</Link></li>
                        <li><Link to="/landscaping-and-lawn-care-business-insurance">Landscaping And Lawn Care Business Insurance</Link></li>
                        <li><Link to="/it-contractors-insurance">IT Contractors Insurance</Link></li>
                        <li><Link to="/restaurant-and-bar-insurance">Restaurant And Bar Insurance</Link></li>
                        <li><Link to="/plumbing-and-hvac-contractors-insurance">Plumbing And Hvac Contractors Insurance</Link></li>
                        <li><Link to="/insurance-for-accountants-and-bookkeepers">Insurance For Accountants And Bookkeepers</Link></li>
                        <li><Link to="/food-truck-and-caterer-insurance">Food Truck And Caterer Insurance </Link></li> 
                        <li><Link to="/tow-truck-insurance">Tow Truck Insurance</Link></li>
                        <li><Link to="/insurance-for-religious-organizations">Insurance For Religious Organizations</Link></li>
                        <li><Link to="/roofers-insurance">Roofers Insurance</Link></li>
                        <li><Link to="/electrical-and-wiring-contractors-insurance">Electrical And Wiring Contractors Insurance</Link></li>
                        <li><Link to="/salon-spa-and-barber-shop-insurance">Salon Spa And Barber Shop Insurance</Link></li>
                        <li><Link to="/insurance-for-real-estate-agents">Insurance For Real Estate Agents</Link></li>
                    </ul>
                </div>
                <div className="flex-item item-company">
                    <h5>Company</h5>
                    <ul className="footer_list">
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><Link to="/contact-us">Contact</Link></li>
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    </ul>
                </div>
            </div>
            <div id="footer_bottom">
                <div id="social_links">
                    <a href="https://www.linkedin.com/company/insurance321-com" target="_blank"><img src={li_icon} /></a>
                    <a href="https://www.facebook.com/Insurance321/" target="_blank"><img src={fb_icon} /></a>
                    <a href="https://www.twitter.com/insurance321/" target="_blank"><img src={twitter_icon} /></a>
                </div>
                <div className="social-right">
                    <div className="footer-icon-text">
                        <img src={phone_icon} />
                        <div className="footer-right-text">(234) 230-6321</div>
                    </div>
                    <div className="footer-icon-text">
                        <img src={clock_icon} />
                        <div className="footer-right-text">Mon — Fri | 8:00am - 8:00pm EST<br />Sat | 9:00am - 5:00pm EST</div>
                    </div>
                </div>
                {/* <div id="bp_logo"><img src={bp_logo} /></div> */}
            </div>
        </div>
    )
}